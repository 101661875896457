<template>
  <div class="header">

    <q-input label="New template name" v-if="!template.value" v-model="newTemplateName" type="text" />

    <div class="input-btn">
        <q-select
          label="Existing templates"
          v-model="template.value"
          :options="template.select"
          placeholder="template"
          @input="changeTemplate"
          emit-value
        />
      <div class="input-btn-sp">
        <q-btn :disabled="canSave()" @click="confirmsave" label="Save" color="primary" class="btnsp" />
      </div>
    </div>
  </div>
</template>

<script>
const equal = require('fast-deep-equal');

export default {
  components: {},
  data() {
    return {
      newTemplateName: '',
    };
  },
  computed: {
    template: {
      get() {
        return this.$store.state.importExport.template;
      },
      set(value) {
        this.$store.commit('importExport/setTemplate', value);
      },
    },
    importExport: {
      get() {
        return this.$store.state.importExport;
      },
      set(data) {
        // this.$store.commit('importExport/setStep3', data);
      },
    },
  },
  methods: {
    canSave() {
      if (this.template.value) {
        return !this.template.canSave;
      }
      return !this.newTemplateName;
    },
    compareTemplates(t1, t2) {
      if (t2 && t1) {
        if (
          !equal(t1.config.format, t2.config.format)
          || !equal(t1.options, t2.options)
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    isExpanded(elem) {
      return elem && elem && elem.expanded;
    },
    isFourColumn(attribute) {
      const twoColAttribute = ['pricing', 'interval'];
      return attribute && attribute && twoColAttribute.includes(attribute.type);
    },
    getRealIndex(index) {
      let corrector = 0;
      for (let i = 0; i < index; i += 1) {
        if (this.isExpanded(this.importExport.step3.draggableRow[i][0])) {
          if (this.isFourColumn(this.importExport.step3.draggableRow[i][0])) {
            corrector += 2;
          } else {
            corrector += 1;
          }
        }
      }
      return index + corrector;
    },
    getColumnsFromAttribute(attribute, index) {
      if (this.isExpanded(attribute)) {
        const realIndex = this.getRealIndex(index);
        if (this.isFourColumn(attribute)) {
          return [realIndex, realIndex + 1, realIndex + 2, realIndex + 3];
        }
        return [realIndex, realIndex + 1];
      } if (this.isFourColumn(attribute)) {
        const realIndex = this.getRealIndex(index);
        return [realIndex, realIndex + 1];
      }
      return [this.getRealIndex(index)];
    },
    getMapping() {
      return this.importExport.step3.draggableRow.reduce(
        (accumulator, current, index) => {
          if (current[0] && current[0].type === 'segmentation') {
            accumulator[current[0].name] ??= [];
            accumulator[current[0].name].push(index);
          } else if (
            current[0]
            && current[0].type !== 'interval-to'
            && current[0].type !== 'price-unit'
          ) {
            accumulator[current[0].name] = this.getColumnsFromAttribute(
              current[0],
              index,
            );
          }
          return accumulator;
        },
        {},
      );
    },
    confirmsave() {
      this.$q.dialog({
        title: this.$t('Do you want to save this template?'),
        message: this.$t('This will overwrite the current definition if any.'),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        this.save();
      });
    },
    save() {
      const mappedData = {
        config: {
          format: {
            locale: this.importExport.step3.locales.value,
            encoding: this.importExport.step2.form.encoding.value,
            separator: this.importExport.step2.form.separator.value,
            headingLines: this.importExport.step2.form.headingLines.value,
            defaultCurrency: this.importExport.step3.currencies.value,
          },
          mapping: this.getMapping(),
        },
        options: {
          noinsert: this.importExport.step2.form.checkbox.value.includes('noinsert'),
          noupdate: this.importExport.step2.form.checkbox.value.includes('noupdate'),
        },
        frontfriendly: true,
      };

      const selectedTemplate = this.template.data.find((t) => t.name === this.template.value);

      if (selectedTemplate && selectedTemplate.id) {
        mappedData.name = selectedTemplate.name;
        this.$axios
          .patch(`/importstemplates/${selectedTemplate.id}`, mappedData)
          .then((res) => {
            this.$axios.get('/importstemplates').then((r) => {
              this.$store.commit('importExport/setImportsTemplates', r.data);
              this.checkTemplate();
            });
          });
      } else {
        mappedData.name = this.newTemplateName;
        this.$axios.post('/importstemplates', mappedData).then((response) => {
          this.$axios.get('/importstemplates').then((r) => {
            this.$store.commit('importExport/setImportsTemplates', r.data);
            this.template.value = this.newTemplateName;
            this.changeTemplate();
          });
        });
      }
    },
    changeTemplate() {
      this.$store.dispatch('importExport/changeTemplate', this.template);
    },
    checkTemplate() {
      this.$store.commit('importExport/checkTemplate');
    },
  },
  created() {},
};
</script>

<style lang="stylus" scoped>

.input-btn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.input-btn .q-field {
  width: 100%;
}

.input-btn-sp {
  padding-bottom: 4px;
}
</style>
