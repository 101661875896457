<template>
    <q-page-container>
      <q-page>
        <div class="row mainexport" v-if="$store.state.pricing.defs">
          <div class="col tree">
            <div class="head1"><span class="round bg-primary text-white">1</span> Families</div>
            <div class="padded">
              <Treeselect  :multiple="true"
                :clearable="true" :placeholder="$t('Please make a selection')"
                :maxHeight="20000" zIndex="7000" :alwaysOpen="true" v-model="families"
                :options="allfamilies" :load-options="loadOptions" />
            </div>
          </div>
          <div class="col criteria">
            <div class="head1"><span class="round bg-primary text-white">2</span> Statuses</div>
            <q-list>
                <q-item-label header>Synchronisation Statuses</q-item-label>
                <q-select
                  dense filled square
                  label="Select sync attribute"
                  :options="syncAttributes"
                  v-model="syncSelected" />
                <q-item>
                <q-item-section>
                  <q-option-group
                    :options="options"
                    label="Sync Statuses"
                    type="checkbox"
                    v-model="v1"
                    />
                </q-item-section>
                </q-item>
            </q-list>
            <q-list>
              <q-item-label header>Pricing related statuses</q-item-label>
              <q-item>
                <q-item-section>
                  <q-option-group :options="pricingopts" type="checkbox" v-model="v2" />
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col attributes">
            <div class="head1"><span class="round bg-primary text-white">3</span> Attributes</div>
            <div class="padded">
              <q-select dense filled square label="Lookup for any attribute" :options="attributes" use-input
                input-debounce="0"  @filter="attributefilter" v-model="v3" @input="attributeselected" />
              <div class="padded">
                <span class="lg-a-chip" removable v-for="(s, index) in selection" :key="s.value">
                  {{s.label}}
                  <q-select v-if="s.vtags && s.vtags.length" map-options emit-value class="bg-white inline-box" filled square dense :options="s.vtags"
                  v-model="vtags[s.value]"/>
                  <q-checkbox checked-icon="calendar_month" dense
      unchecked-icon="event_busy" indeterminate-icon="help" v-if="series[s.value] !== undefined" :disable="s.value === 'price'" v-model="series[s.value]"/>
                  <span class="material-icons" @click="remove(index)">close</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <q-input fill-mask filled square dense v-model="when" mask="####-##-##">
            <template v-slot:prepend>
              <q-icon name="event" />
            </template>
          </q-input>
          <q-btn :loading="loading" color="secondary" @click="exportData">Export</q-btn>
        </div>
      </q-page>
    </q-page-container>
</template>
<script>
import { Treeselect, LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { localization } from '../../lib/mixins';

const statuses = {
  waiting: { label: 'Waiting to be sent to ERP', badge: 'W', color: 'orange' },
  transmitted: { label: 'Transmitted to ERP', badge: 'T', color: 'light-green' },
  active: { label: 'Active, fully implemented', badge: 'A', color: 'positive' },
  rejected: { label: 'Rejected by ERP import tool', badge: 'R', color: 'negative' },
  inconsistent: { label: 'Inconsistent between pricing and ERP', badge: 'I', color: 'negative' },
  acknowledged: { label: 'Accepted for processing by ERP', badge: 'K', color: 'light-green' },
};

export default {
  mixins: [localization],
  components: { Treeselect },
  data() {
    return {
      vtags: {},
      series: {},
      when: (new Date()).toISOString().split('T')[0],
      loading: false,
      families: null,
      allfamilies: null,
      selection: [],
      attributes: this.allattributes,
      v1: [],
      v2: [],
      syncSelected: null,
      v3: null,
      pricingopts: [
        { value: 'purchasealert', label: 'Purchase Alert' },
        { value: 'auto', label: 'Price was given by the rule' },
        { value: 'manual', label: 'Price was set manually (forced)' },
        { value: 'obsolete', label: 'A new price is expected' },
      ],
      options: Object.entries(statuses).map(([k, v]) => ({ value: k, label: v.label })),
    };
  },
  watch: {
    syncAttributes(attributes) {
      if (this.syncSelected === null && attributes.length > 0) {
        this.syncSelected = attributes.find((attr) => attr.value === 'price');
      }
    },
  },
  methods: {
    remove(i) {
      this.selection.splice(i, 1);
    },
    exportData() {
      this.loading = true;
      const locale = (this.$store.state.auth.user && this.$store.state.auth.user.locale)
        ? this.$store.state.auth.user.locale
        : 'en-US';
      const params = {
        when: this.when,
        locale,
        '@filters': [],
        columns: this.selection.map((a) => {
          if (this.vtags[a.value] === undefined && this.series[a.value] !== true) return a.value;
          if (!this.series[a.value]) return `${a.value}:tag=${this.vtags[a.value]}`;
          if (this.vtags[a.value] === undefined) return `${a.value}:tag=null:witht`;
          return `${a.value}:tag=${this.vtags[a.value]}:witht`;
        }).join(','),
      };
      if (this.families && this.families.length) {
        params['@filters'].push({
          column: 'families',
          op: 'belongsto',
          args: this.families,
        });
      }
      if (this.v1.length) {
        params['@filters'].push({
          column: `${this.syncSelected.value || 'price'}:status`,
          op: 'includes',
          args: this.v1,
        });
      }
      this.v2.forEach((field) => {
        switch (field) {
          case 'purchasealert':
          case 'obsolete':
            params['@filters'].push({
              column: field,
              op: 'equals',
              args: [true],
            });
            break;
          case 'auto':
          case 'manual':
            params['@filters'].push({
              column: 'pricestatus',
              op: 'equals',
              args: [field],
            });
            break;
          default:
        }
      });
      params['@filters'] = JSON.stringify(params['@filters']);
      this.$axios.get('/products/download', { params, responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx');
        document.body.appendChild(link);
        link.click();
      })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Export failed', icon: 'warning' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadOptions({ action, parentNode, callback }) {
      switch (action) {
        case LOAD_CHILDREN_OPTIONS:
        case LOAD_ROOT_OPTIONS:
          this.$axios
            .get(parentNode
              ? `/families/${parentNode.id}/children`
              : '/families', { params: { nolimit: 'yes', sort: 'label' } })
            .then((response) => {
              const options = response.data.data.map((f) => ({ id: f.id, children: null, label: this.localized(f.label) }));
              if (parentNode) {
                parentNode.children = options;
              } else {
                this.allfamilies = options;
              }
              callback();
            })
            .catch((error) => callback(error));
          break;
        default:
          break;
      }
    },
    attributeselected(v) {
      this.selection.push(v);
      this.v3 = null;
      const attr = Object.values(this.$store.state.pricing.defs.defs).find((a) => (a.name === v.value));

      if (attr.vtags && attr.vtags.length) this.$set(this.vtags, v.value, attr.vtags[0]);
      if (attr.timeserie) this.$set(this.series, v.value, v.value === 'price');
    },
    attributefilter(val, update) {
      val = val.toLowerCase();
      if (val === '') {
        update(() => {
          this.attributes = this.allattributes;
        });
        return;
      }
      update(() => {
        this.attributes = this.allattributes.filter((a) => a.label.toLowerCase().includes(val));
      });
    },
  },
  computed: {
    allattributes() {
      return Object.values(this.$store.state.pricing.defs.defs)
        .filter(({ name, boolset }) => !boolset && (this.selection.findIndex((s) => (s.value === name)) < 0))
        .map((a) => ({ value: a.name, label: this.localized(a.label), vtags: a.vtags }))
        .concat(Object.values(this.$store.state.pricing.sets.sets)
          .filter(({ boolset }) => boolset)
          .map(({ name, label }) => ({ value: `${name}:boolset`, label: `${this.localized(label)} (Flag Set)` })));
    },
    syncAttributes() {
      return Object.values(this.$store?.state?.pricing?.defs?.defs2 || {})
        .filter((attr) => attr?.sync?.length > 0)
        .map((attr) => ({ value: attr.name, label: this.localized(attr.label), vtags: attr.vtags }));
    },
  },
};
</script>
<style lang="stylus" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: .2rem 1rem;
  >>> .q-field {
    padding: 0
    padding-right: 1rem
  }
}
.mainexport {
  height calc(100vh - 98px)
  .col {
    .padded {
      padding-top: 1rem;
    }
    .lg-a-chip {
      display inline-block
      border: 1px solid gray
      padding 0rem .5rem
      border-radius 1rem
      line-height: 2rem
      margin-right: .2rem
      .inline-box {
        display inline-block

      }
      .q-field {
        padding: 0;
        margin: 0 .2rem;
      }
      >>> .q-field__append, >>> .q-field__native, >>> .q-field__control {
        height: 26px
        min-height 26px
      }
      >>> .q-field__control {
        padding: 0 .2rem;
      }
    }
    >>> .vue-treeselect--open .vue-treeselect__control {
        border: 0;
        background-color: #f2f2f2;
        border-radius: 0;
    }
    >>> .vue-treeselect--open-below .vue-treeselect__menu {
      box-shadow none
      border: 0
    }
    >>> .vue-treeselect__menu {
      box-shadow none
      border: 0
    }
    padding: 1rem
    height: 100%;
    overflow: auto;

    .head1 {
      font-size 1.4rem
      .round {
        border-radius 50%
        width 2rem
        display inline-block
        text-align center
      }
    }
  }
}
</style>
