import axios from 'axios';
import { localization } from '../../lib/mixins';
import putils from '../../lib/pictures';

const stateLoc = {
  history: {
    families: localStorage.getItem('segmentation_history') ? JSON.parse(localStorage.getItem('segmentation_history')).families : [],
    values: localStorage.getItem('segmentation_history') ? JSON.parse(localStorage.getItem('segmentation_history')).values : [],
    material: localStorage.getItem('segmentation_history') ? JSON.parse(localStorage.getItem('segmentation_history')).material : [],
  },
  showNotificationCb: null,
};

const actions = {
  async init({ state }, showNotificationCb) {
    state.showNotificationCb = showNotificationCb.bind(this);
  },
  showNotification({ state }, { type, message, icon }) {
    if (typeof state.showNotificationCb === 'function') {
      state.showNotificationCb(type, message, icon);
    }
  },

  // FAMILIES
  async getFamiliesImgs({ rootState, state }, id) {
    const columns = ['pictures'].join(',');
    const response = await axios.get('/products', {
      params: {
        columns, '@limit': 1, '@offset': 0, '@filters': JSON.stringify([{ column: 'pictures', op: 'not_empty', args: [] }, { column: 'families', op: 'belongsto', args: [id] }]),
      },
    });
    const { data } = response.data;
    if (data && data.length) {
      return putils.mkpath(data[0]?.pictures?.v[0], rootState.auth.token);
    }
    return '/default.png';
  },
  async updateFamilyQuickOverview({ state }) {
    console.log('updateFamilyQuickOverview', state.history.families);
    await Promise.all(state.history.families.map(async (f) => {
      try {
        const res2 = await axios.get(`/families/${f.id}/quick-overview`);
        f.nbParts = res2.data.data.count;
        let turnover = res2.data.data.turnover.number;
        f.unit = res2.data.data.turnover.unit || '';
        if (turnover > 1000) {
          turnover = `${(turnover / 1000).toFixed(0)} K`;
        } else {
          turnover = turnover.toFixed(0);
        }

        f.turnover = `${turnover}`;
        console.log('family', f);
      } catch (error) {
        console.error('Error fetching quick overview:', error);
      }
    }));
  },
  async getFamilies({ dispatch }, id = null) {
    if (!id) {
      const response = await axios.get('/families', { params: { nolimit: 'yes', sort: 'label' } });
      const tmpData = await Promise.all(response.data.data.map(async (f) => ({
        id: f.id,
        parent: f.parent ? f.parent : null,
        label: localization.methods.localized(f.label, 'en-US'),
        name: f.name,
        leaf: f.leaf,
        img: await dispatch('getFamiliesImgs', f.id),
      })));
      return tmpData;
    }

    const response = await axios.get(`/families/${id}/siblings`, { params: { nolimit: 'yes', sort: 'label' } });
    const tmpData = await Promise.all(response.data.data.map(async (f) => ({
      id: f.id,
      parent: f.parent ? f.parent : null,
      label: localization.methods.localized(f.label, 'en-US'),
      name: f.name,
      leaf: f.leaf,
      img: await dispatch('getFamiliesImgs', f.id),
    })));
    return tmpData;
  },
  async updateFamily({ state, dispatch }) {
    await Promise.all(state.history.families.map((f) => dispatch('getFamilies', f.id)));
  },
  async deleteFamily({ state, dispatch }, familyId) {
    if (familyId === state.defaultSegmentation) {
      dispatch('showNotification', { type: 'negative', message: 'You cannot delete the default segmentation', icon: 'warning' });
      return;
    }

    try {
      await axios.delete(`/families/${familyId}`);
      dispatch('showNotification', { type: 'positive', message: 'Family got deleted', icon: 'done' });
      state.history.families = state.history.families.filter((f) => f.id !== familyId);
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'Something wrong happened trying to delete the family', icon: 'warning' });
    }
  },
  async move({ dispatch }, { parts, familyId }) {
    if (!parts.length) {
      dispatch('showNotification', { type: 'negative', message: 'Please select at least one part to move', icon: 'warning' });
      return;
    }

    if (!familyId) {
      dispatch('showNotification', { type: 'negative', message: 'Please select a family to move the part(s) to', icon: 'warning' });
      return;
    }

    try {
      await axios.post('/products/move', {
        family: familyId,
        parts: parts.map((item) => item.id),
      });
      dispatch('showNotification', { type: 'positive', message: 'Part(s) got moved', icon: 'done' });
      // dispatch('updateSegmentation');
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'Something wrong happened trying to move the part(s)', icon: 'warning' });
      // dispatch('updateSegmentation');
    }
  },

  // MATERIAL
  async addMaterial({ dispatch }, { ids, params }) {
    try {
      const res = await axios.patch('/products', params);
      dispatch('showNotification', { type: 'positive', message: `Materials have been added for ${ids.length} products`, icon: 'done' });
      return res;
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'The attribute cannot be updated', icon: 'warning' });
      return null;
    }
  },

  // HISTORY FAMILY
  async addHistoryFamilies({ state, dispatch }, { familyId }) {
    const res = await dispatch('getFamilies', familyId);
    const family = res.find((f) => f.id === familyId);

    state.history.families.unshift(family);

    localStorage.setItem('segmentation_history', JSON.stringify(state.history));
  },
  async removeHistoryFamilie({ state }, { familyId }) {
    state.history.families = state.history.families.filter((f) => f.id !== familyId);
    localStorage.setItem('segmentation_history', JSON.stringify(state.history));
  },

  // HISTORY MATERIAL
  async addHistoryMaterial({ rootState, state }, params) {
    const attributes = Object.values(rootState.pricing.defs.defs);
    // Iterate over the keys in params
    Object.keys(params).forEach((key) => {
      // Check if the value is true
      if (params[key] === true) {
        // Find the attribute with the name matching the key
        const material = attributes.find((att) => att.name === key);
        // Check if the material is already in the history
        const exists = state.history.material.some((m) => m.name === key);
        if (!exists && material) {
          state.history.material.unshift(material);
          if (state.history.material.length > 5) {
            state.history.material.pop();
          }
        }
      }
      localStorage.setItem('segmentation_history', JSON.stringify(state.history));
    });
  },
  async removeHistoryMaterial({ state }, materialId) {
    state.history.material = state.history.material.filter((m) => m.id !== materialId);
    localStorage.setItem('segmentation_history', JSON.stringify(state.history));
  },

  // HISTORY VALUE
  async addHistoryValues({ rootState, state }, params) {
    const attributes = Object.values(rootState.pricing.defs.defs);

    // Iterate over the keys in params
    Object.keys(params).forEach((key) => {
      // Check if the value is true
      if (params[key] === true) {
        // Find the attribute with the name matching the key
        const value = attributes.find((att) => att.name === key);
        // Check if the value is already in the history
        const exists = state.history.values.some((v) => v.name === key);
        if (!exists && value) {
          state.history.values.unshift(value);
          if (state.history.values.length > 5) {
            state.history.values.pop();
          }
        }
      }
      localStorage.setItem('segmentation_history', JSON.stringify(state.history));
    });
  },
  async removeHistoryValue({ state }, valueId) {
    state.history.values = state.history.values.filter((v) => v.id !== valueId);
    localStorage.setItem('segmentation_history', JSON.stringify(state.history));
  },

};

export default {
  namespaced: true,
  state: stateLoc,
  mutations: null,
  actions,
};
