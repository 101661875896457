<template>
  <div>
    <p class="selected-text">
      {{ selectionMessage }}
    </p>
    <div class="content">
      <p class="drawerTitle">
        Families and sub-families
        <q-icon name="add" class="add-icon" @click="openDialog('create-family')" />
      </p>
      <div class="itemContainer">
        <q-btn class="item-type1" :color="'secondary'" @click="openDialog('select-family')">Select family</q-btn>
        <div class="item-type3" v-for="family in families" :key="family.id + family.nbParts"
          @click="openDialog('apply-family', family)">
          <div class="item-type1" style="height:80px;">
            <p style="margin-top:-5px">{{ family.label }}</p>
            <q-tooltip>
              {{ family.label }}<br />
              Nb parts: {{ family.nbParts }}<br />
              Turnover: {{ family.turnover }}{{ family.unit }}
            </q-tooltip>
            <q-icon name="close" class="close-icon" @click.stop="removeFamilie(family.id)" />
            <img :src="family.img" class="inline-picture" @error="handleImageError" style="margin-bottom:-5px" />
          </div>
          <p :key="family.nbParts" style="margin-top: -10px; margin-bottom: -3px; font-size: 10px !important;">Nb parts:
            {{ family.nbParts }}
          </p>
          <p :key="family.turnover" style="margin-bottom: 0px; font-size: 10px !important;">T: {{ family.turnover
            }}</p>
        </div>
      </div>
    </div>

    <div class="content">
      <p class="drawerTitle">
        Added values
        <q-icon name="add" class="add-icon" @click="openDialog('create-value')" />
      </p>
      <div class="itemContainer">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-value')"
          style="padding:0; font-size: 0.7em;">Select value</q-btn>
        <div v-for="value in this.$store.state.segmentation.history.values" :key="value.id" class="item-type2"
          @click="openDialog('apply-value', value)">
          <p>{{ localized(value.label['en-US']) }}</p>
          <q-tooltip>
            {{ localized(value.label['en-US']) }}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeValue(value.id)" />
        </div>
      </div>
    </div>

    <div class="content" style="border:none;">
      <p class="drawerTitle">
        Material
        <q-icon name="add" class="add-icon" @click="openDialog('create-material')" />
      </p>
      <div class="itemContainer">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-material')"
          style="padding:0; font-size: 0.7em;">Select material</q-btn>
        <div v-for="material in this.$store.state.segmentation.history.material" :key="material.id" class="item-type2"
          @click="openDialog('apply-material', material)">
          <p>{{ localized(material.label['en-US']) }}</p>
          <q-tooltip>
            {{ localized(material.label['en-US']) }}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeMaterial(material.id)" />
        </div>
      </div>
    </div>

    <CreateFamilyDialog :model="showCreateFamilyDialog" @update:model="showCreateFamilyDialog = $event"
      @confirm="confirmAction" />

    <SelectFamilyDialog :selectedFamily="selectedFamily" :model="showSelectFamilyDialog"
      @update:model="showSelectFamilyDialog = $event;" @confirm="confirmAction" />

    <CreateAttrDialog :model="showCreateAttrDialog" :selected="selected" :set="createAttrSet"
      @update:model="showCreateAttrDialog = $event" @confirm="confirmAction" />

    <SelectValuesDialog :model="showSelectValuesDialog" :selected="selected" :attributes="attributes"
      @update:model="showSelectValuesDialog = $event" @confirm="confirmAction" />

    <SelectMaterialDialog :model="showSelectMaterialDialog" :selected="selected" :attributes="attributes"
      @update:model="showSelectMaterialDialog = $event" @confirm="confirmAction" />

    <ConfirmDialog :model="showConfirmDialog" :text="confirmDialogText" @update:model="showConfirmDialog = $event"
      @confirm="confirmAction" />

  </div>
</template>

<script>
import localization from '../../../lib/mixins'; //eslint-disable-line
import CreateFamilyDialog from './dialog/CreateFamilyDialog.vue';
import SelectFamilyDialog from './dialog/SelectFamilyDialog.vue';
import CreateAttrDialog from './dialog/CreateAttrDialog.vue';
import SelectValuesDialog from './dialog/SelectValuesDialog.vue';
import SelectMaterialDialog from './dialog/SelectMaterialDialog.vue';
import ConfirmDialog from './dialog/ConfirmDialog.vue';

export default {
  mixins: [localization],
  props: ['data', 'selected'],
  components: {
    CreateFamilyDialog,
    SelectFamilyDialog,
    SelectValuesDialog,
    CreateAttrDialog,
    SelectMaterialDialog,
    ConfirmDialog,
  },
  data() {
    return {
      locale: this.$store.state.auth.locale,

      showCreateFamilyDialog: false,
      showSelectFamilyDialog: false,
      showCreateAttrDialog: false,
      showSelectValuesDialog: false,
      showSelectMaterialDialog: false,
      showConfirmDialog: false,

      createAttrSet: null,
      createAttrType: '',
      selectedFamily: null,
      selectedValue: null,
      selectedMaterial: null,
      confirmDialogText: '',

      actionType: '',
    };
  },
  computed: {
    defs() {
      const { defs } = this.$store.state.pricing.defs;
      return Object.entries(defs)
        .filter(([, attr]) => !this.data.data.columns.includes(attr.name))
        .map(([name, attr]) => ({
          label: `${attr.type} --- ${this.localized(attr.label)}`,
          value: attr.name,
          id: name,
        }));
    },
    sets() {
      const { sets } = this.$store.state.pricing.sets;
      return Object.keys(sets)
        .map((key) => sets[key])
        .filter((set) => set.name === 'materials' || set.name === 'features');
    },
    attributes() {
      return Object.values(this.$store.state.pricing.defs.defs);
    },
    selectionMessage() {
      return this.selected.length ? `${this.selected.length} Parts selected` : 'No parts selected';
    },
    families() {
      return this.$store.state.segmentation.history.families;
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    handleImageError(event) {
      event.target.src = '/default.jpg';
    },

    checkBeforeDialog() {
      if (this.selected.length === 0) {
        this.showNotification('negative', 'Please select at least one part', 'warning');
        return false;
      }
      return true;
    },
    openDialog(actionType, item = null) {
      if (actionType !== 'create-family'
        && actionType !== 'select-family'
        && actionType !== 'create-value'
        && actionType !== 'create-material') {
        if (!this.checkBeforeDialog()) return;
      }

      const materialSet = this.sets.find((set) => set.name === 'materials');
      const valueSet = this.sets.find((set) => set.name === 'features');

      this.actionType = actionType;
      switch (actionType) {
        // Family actions
        case 'create-family':
          this.showCreateFamilyDialog = true;
          break;
        case 'select-family':
          this.showSelectFamilyDialog = true;
          break;
        case 'apply-family':
          this.selectedFamily = item.id;
          this.confirmDialogText = `You are about to move the ${this.selected.length} selected parts to the family "${item.label}", are you sure?`;
          this.showConfirmDialog = true;
          break;
        // Value actions
        case 'create-value':
          this.createAttrType = 'value';
          if (materialSet) {
            this.createAttrSet = valueSet.id;
            console.log('create-value', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-value':
          this.showSelectValuesDialog = true;
          break;
        case 'apply-value':
          this.selectedValue = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
        // Material actions
        case 'create-material':
          this.createAttrType = 'material';
          if (materialSet) {
            this.createAttrSet = materialSet.id;
            console.log('create-material', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-material':
          this.showSelectMaterialDialog = true;
          break;
        case 'apply-material':
          this.selectedMaterial = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
        default:
          break;
      }
    },

    async addHistoryFamilies(familyId) {
      this.$store.dispatch('segmentation/addHistoryFamilies', { familyId });
    },
    async updateFamily() {
      this.$store.dispatch('segmentation/updateFamily');
    },
    removeFamilie(familyId) {
      this.$store.dispatch('segmentation/removeHistoryFamilie', { familyId });
    },
    addHistoryValues(params) {
      this.$store.dispatch('segmentation/addHistoryValues', params);
    },
    removeValue(valueId) {
      this.$store.dispatch('segmentation/removeHistoryValue', valueId);
    },
    addHistoryMaterial(params) {
      this.$store.dispatch('segmentation/addHistoryMaterial', params);
    },
    removeMaterial(materialId) {
      this.$store.dispatch('segmentation/removeHistoryMaterial', materialId);
    },

    confirmAction(data) {
      if (data.actionType) this.actionType = data.actionType;

      // Family actions
      if (this.actionType === 'create-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
      } else if (this.actionType === 'select-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'apply-family') {
        this.move(this.selected, this.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'delete-family') {
        this.deleteFamily(data.toDelete);
        // Value actions
      } else if (this.actionType === 'create-value') {
        const selectedParts = this.selected;
        if (data.attrId) {
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryValues(params);
        }
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-value') {
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryValues(data.params);
        }
      } else if (this.actionType === 'apply-value') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedValue);
        this.addMaterial(partsIds, params);
        // Material actions
      } else if (this.actionType === 'create-material') {
        const selectedParts = this.selected;
        if (data.attrId) {
          console.log('apply new material to parts');
          console.log('data', data.attrId);
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryMaterial(params);
        }
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-material') {
        // Done inside the dialog
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryMaterial(data.params);
        }
      } else if (this.actionType === 'apply-material') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
        this.addMaterial(partsIds, params);
      }

      this.showConfirmDialog = false;
      this.actionType = '';
    },

    async deleteFamily(familyId) {
      this.$store.dispatch('segmentation/deleteFamily', familyId);
    },

    async move(parts, familyId) {
      await this.$store.dispatch('segmentation/move', { parts, familyId });
      await this.emitUpdate();
      await this.$store.dispatch('segmentation/updateFamilyQuickOverview');
      this.$forceUpdate();
    },

    makeMaterialParams(ids, materialId) {
      const attributesValues = this.attributes.reduce((res, att) => {
        res[att.id] = {
          ...att,
          ...(att.type === 'number' ? { number: null, fixed: null, unit: '' } : {}),
          ...(att.type === 'boolean' ? { checked: att.id === materialId } : {}),
        };
        return res;
      }, {});

      const booleans = Object.keys(attributesValues)
        .filter((att) => attributesValues[att].checked)
        .map((filteredAttribute) => ({ [attributesValues[filteredAttribute].name]: attributesValues[filteredAttribute].checked }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      const numbers = Object.keys(attributesValues)
        .filter((attribute) => attributesValues[attribute].number != null)
        .map((filteredAttribute) => ({
          [attributesValues[filteredAttribute].name]: {
            number: Number(attributesValues[filteredAttribute].number),
            fixed: `${attributesValues[filteredAttribute].number}`,
            unit: attributesValues[filteredAttribute].unit,
          },
        }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      console.log('booleans', booleans);
      console.log('numbers', numbers);
      const params = {
        ...booleans,
        ...numbers,
      };

      return params;
    },
    async addMaterial(ids, params) {
      const res = await this.$store.dispatch('segmentation/addMaterial', { ids, params });
      this.$emit('confirm', res);
    },
    showNotification(type, message, icon) {
      this.$q.notify({ type, message, icon });
    },
  },

  async created() {
    this.$store.state.catalog.segmentationDrawerIsOpen = true;
    await this.$store.dispatch('segmentation/init', this.showNotification);
  },
  async beforeDestroy() {
    this.$store.state.catalog.segmentationDrawerIsOpen = false;
  },
};
</script>

<style lang="stylus" scoped>

.ctrl-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  //flex-wrap: wrap;
  align-items: center;
  //margin-bottom: 1em;
  padding: 0 1em;
}

.item-type3{
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-radius: 5px;
   margin-right: 6px;
  height: 110px;
  width:75px;
  overflow: hidden;

  p{
    margin-left:5px;
    cursor: default;
  }
}

.segmentation-button{
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.84em;
  height: 50px;
}

.selected-text{
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom:0.5em;
  color: #ff8700;
  width: 100%;
  text-align: center;
  margin-top: 1em;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top:  1em;
  border-bottom: 1px solid #ccc;
}

.drawerTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  width: 100%;
  text-align: center;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}

.add-icon{
  cursor: pointer;
  font-size:1.4em;
}
.add-icon:hover {
  transform: scale(1.2);
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px !important;
  overflow-y: scroll;
  padding: 0 1em;
}

.item-type1
.item-type2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  height: 110px;
  width:75px;
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 8px;
  &:hover {
    background-color: #f0f0f0;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    width: 100%;
  }

  img {
    height: 50px;
    border: 1px solid #ccc;
    object-fit: contain;
    border-radius: 5px;
  }
}

.item-type1{
  flex-direction: column;
  position : relative;
  .close-icon {
    position: absolute;
    top: 3px;
    right:2px;
  }
}

.item-type2 {
  flex-direction: row;
  justify-content: center
  height: 40px;
  width: 115px;

  p {
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 8px;
  &:hover {
    color: red;
  }
}
</style>
