import { Notify } from 'quasar';

/*
export function someAction (context) {
}
*/

// import attributesDefinitions from '../../mock/attribute-definitions';
import Papa from 'papaparse'; // doc here https://www.papaparse.com/docs
import xlsx from 'xlsx'; // eslint-disable-line

export const setAttributes = (context) => {
  context.commit('setStep3', {
    attributesList: context.rootState.pricing.defs.defs,
    setList: context.rootState.pricing.sets.sets,
    settings: context.rootState.general.settings,
  });
};

const getFiltredData = (state, data) => {
  const headerLines = state.step2.form.headingLines.value + 10;
  const footerLines = state.step2.form.headingLines.value + 10;
  if (data.length > headerLines + footerLines) {
    return [
      ...data.slice(0, headerLines),
      ...data.slice(data.length - footerLines, data.length),
    ];
  }
  return data;
};

export const parseFile = ({ commit, state }, file) => new Promise((resolve, reject) => {
  const rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;
  switch (file.type) {
    case 'text/csv':
      Papa.parse(file, {
        skipEmptyLines: true,
        delimiter: state.step2.form.separator.value,
        encoding: state.step2.form.encoding.value,
        complete: (results) => {
          if (results.data) {
            commit('setFile', {
              uploaded: file,
              parsed: getFiltredData(state, results.data),
            });
            resolve();
          } else {
            reject();
          }
        },
        error(err) {
          reject(err);
        },
      });
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const wb = xlsx.read(data, { type: rABS ? 'binary' : 'base64' });
          const parsed = xlsx.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
          commit('setFile', { uploaded: file, parsed: getFiltredData(state, parsed) });
          commit('setStep3', { format: { locale: 'en_US' } });
          resolve();
        };
        reader.onerror = (e) => reject();
        reader.readAsBinaryString(file);
      }
      break;
    default:
      reject();
  }
});

// const getRealIndex = (state, index) => {
//   let corrector = 0;
//   for (let i = 0; i < index; i += 1) {
//     if (state.step1.file.expandedCollumns[i]) {
//       corrector += 1;
//     }
//   }
//   return index + corrector;
// };

const isExpanded = (elem) => elem && elem.expanded;
const isFourColumn = (attribute) => attribute && attribute.expandable && attribute.expandable.includes(4);
const getCellAttribute = (draggableRow, index) => {
  if (draggableRow[index] && draggableRow[index][0]) {
    return draggableRow[index][0];
  }
  return null;
};

const getColspan = (attribute) => {
  const twoColAttribute = ['pricing', 'interval'];
  return attribute && twoColAttribute.includes(attribute.type);
};

export const dropdraggableRowElem = (
  { dispatch, commit, state },
  { columnIndex, localized },
) => {
  const attribute = getCellAttribute(state.step3.draggableRow, columnIndex);
  if (isExpanded(attribute)) {
    dispatch('unmergeColumn', columnIndex);
  }
  commit('dropdraggableRowElem', columnIndex);
  if (getColspan(attribute)) {
    commit('dropdraggableRowElem', columnIndex + 1);
  }
  commit('updateTagFilter', {
    value: state.step3.tagFilter.value,
    localized,
  });
};

export const mergeColumn = ({ commit, state }, columnIndex) => {
  if (state.step3.draggableRow[columnIndex + 1] !== undefined) {
    const attribute = getCellAttribute(state.step3.draggableRow, columnIndex);
    const nextAttribute = getCellAttribute(state.step3.draggableRow, columnIndex + 1);
    if (nextAttribute) {
      if (isFourColumn(attribute)) {
        const thirdAttribute = getCellAttribute(
          state.step3.draggableRow,
          columnIndex + 2,
        );
        const fourthAttribute = getCellAttribute(
          state.step3.draggableRow,
          columnIndex + 3,
        );
        if (thirdAttribute || fourthAttribute) {
          Notify.create("Conflict please be shure the two next column dosen't have attribute");
          return;
        }
        commit('moveAttributeRight', columnIndex + 1);
      } else {
        Notify.create("Conflict please be shure the next column dosen't have attribute");
        return;
      }
    }
    commit('setExpanded', {
      index: columnIndex,
      value: true,
    });
    commit('mergeColumn', { columnIndex });
    if (isFourColumn(attribute)) {
      commit('mergeColumn', { columnIndex: columnIndex + 1 });
    }
  }
};

export const unmergeColumn = ({ commit, state }, columnIndex) => {
  if (state.step3.draggableRow[columnIndex + 1] !== undefined) {
    const attribute = getCellAttribute(state.step3.draggableRow, columnIndex);
    // const nextAttribute = getCellAttribute(state.step3.draggableRow, columnIndex + 1);
    commit('setExpanded', {
      index: columnIndex,
      value: false,
    });
    commit('unmergeColumn', columnIndex);
    if (isFourColumn(attribute)) {
      commit('unmergeColumn', columnIndex + 2);
      commit('spliceDraggableRow', {
        index: columnIndex + 2,
        size: 0,
        value: [],
      });
      commit('spliceDraggableRow', {
        index: columnIndex + 3,
        size: 0,
        value: [],
      });
    } else {
      commit('spliceDraggableRow', {
        index: columnIndex + 1,
        size: 0,
        value: [],
      });
    }
  }
};

export const changeTemplate = ({
  dispatch, commit, state, rootState,
}, template) => {
  const t1 = state.template.data.find((t) => t.name === state.template.value);
  state.template.canSave = false;

  if (state.template.value && t1) {
    commit('resetTemplate');
    const keys = Object.keys(t1.config.mapping);
    let correctorIndex = 0;

    const draggableRow = keys
      .sort((a, b) => {
        const ma = t1.config.mapping[a][0];
        const mb = t1.config.mapping[b][0];
        return ma - mb;
      })
      .reduce((ac, v) => {
        const attr = rootState.pricing.defs.defs2[v]; // find(a => v === a.name);
        const mapping = t1.config.mapping[attr.name];

        const startIndex = mapping[0];
        if (isFourColumn(attr)) {
          attr.expanded = true;
        } else if (attr.expandable && attr.expandable.length) {
          attr.expanded = true;
        } else {
          attr.expanded = false;
        }

        if (attr.type === 'segmentation') {
          mapping.forEach((mapIndex) => {
            ac[mapIndex + correctorIndex] = [attr];
          });
        } else {
          ac[startIndex + correctorIndex] = [attr];
        }

        switch (attr.type) {
          case 'pricing':
            ac[startIndex + 1] = [
              {
                expanded: false,
                label: 'price-unit',
                name: 'price-unit',
                type: 'price-unit',
              },
            ];
            commit('mergeColumn', { columnIndex: startIndex, nosplice: true });
            commit('mergeColumn', { columnIndex: startIndex + 1, nosplice: true });
            correctorIndex -= 2;
            ac.splice(startIndex + 2, 2);
            break;
          case 'interval':
            ac[startIndex + 1] = [
              {
                expanded: false,
                label: 'interval-to',
                name: 'interval-to',
                type: 'interval-to',
              },
            ];
            // commit('mergeColumn', { columnIndex: startIndex, nosplice: true });
            break;
          default:
            break;
        }
        return ac;
      }, Array.from(new Array(state.step1.file.parsed[0].length), () => []));

    commit('setStep2', t1.config.format);
    commit('setStep3', {
      format: t1.config.format,
      settings: rootState.general.settings,
      attributesList: rootState.pricing.defs.defs,
      draggableRow,
    });
  }
};
