<template>
  <div v-if="ready">
    <div class="shadow-2"><q-toolbar class="bg-primary text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>{{getTitle()}}</q-toolbar-title>
      <q-icon
        class="locked"
        :name="locked ? 'lock': 'lock_open'"
        @click.native="toggleLock()"
        size="1.5rem"
      ></q-icon>
    </q-toolbar>
    <q-tabs class="bg-primary text-white" v-model="tab" animated>
      <q-tab default  name="profile" icon="face" :label="$t('Profile')"></q-tab>
      <q-tab name="preferences" icon="tune" :label="$t('Preferences')"></q-tab>
    </q-tabs></div>
    <q-tab-panels v-model="tab">
      <q-tab-panel class="drawer-panel" name="profile">
        <!-- ROLES -->
          <q-select :label="$t('Roles')" :disable="locked"
          placeholder="select" map-options
          v-model="form.role" emit-value
          :options="rolesList" bottom-slots :error="$v.form.role.$error" :error-message="getError($v.form.role)"/>

        <!-- ACTIVE -->
          <q-checkbox
            :disable="locked"
            v-model="form.active"
            :label="$i18n.t(form.active ? 'Active user' : 'Disabled user')"
          />

        <!-- FIRSTNAME -->
          <q-input
            v-model="form.firstname"
            :disable="locked"
            type="text"
            :label="$i18n.t('Firstname')"
            :error="$v.form.firstname.$error" bottom-slots :error-message="getError($v.form.firstname)"
            @input="$v.form.firstname.$touch"
          />

        <!-- LASTNAME -->
          <q-input
            v-model="form.lastname"
            :disable="locked"
            type="text"
            :label="$t('Lastname')"
            @input="$v.form.lastname.$touch"
            :error="$v.form.lastname.$error" bottom-slots :error-message="getError($v.form.lastname)"
          />

        <!-- EMAIL -->
          <q-input
            v-model="form.email"
            :disable="locked"
            type="text"
            :error="$v.form.email.$error" bottom-slots :error-message="getError($v.form.email)"
            :label="$i18n.t('Email')"
            @input="$v.form.email.$touch"
          />

        <!-- PASSWORD -->
          <q-input
            v-model="form.password"
            :disable="locked"
            :error="$v.form.password.$error" :error-message="getError($v.form.password)"
            bottom-slots
            type="password"
            :label="`${action === 'details' ? 'new' : ''} password`"
            @input="$v.form.password.$touch"
          />

        <!-- CONFRIM PASSWORD -->
          <q-input
            v-model="form.confirmPassword"
            :disable="locked"
            :error="$v.form.confirmPassword.$error"
            bottom-slots
            :error-message="getError($v.form.confirmPassword)"
            type="password"
            :label="`Confirm ${action === 'details' ? 'new' : ''} password`"
            @input="$v.form.confirmPassword.$touch"
          />
          <q-input
            v-model="form.publickey"
            :disable="locked"
            type="textarea"
            label="ssh key"
            :max-height="100"
            rows="3"
          />
      </q-tab-panel>
      <q-tab-panel class="drawer-panel" name="preferences">
          <q-select map-options emit-value :label="$t('Locale')" :error="$v.form.locale.$error" bottom-slots :error-message="getError($v.form.locale, 'preferences')" :disable="locked" placeholder="select" v-model="form.locale" :options="locales"/>
      </q-tab-panel>
    </q-tab-panels>
    <div class="bottom q-pa-sm">
        <q-btn @click="goback">{{$t('Cancel')}}</q-btn>
        <q-btn :disable="locked" color="secondary" @click="save()">{{$t('Save')}}</q-btn>
    </div>
  </div>
</template>
<script>
import {
  required, minLength, sameAs, email,
} from 'vuelidate/lib/validators';
import { localization } from '../../../lib/mixins';

export default {
  mixins: [localization],
  data() {
    return {
      tab: 'profile',
      ready: false,
      search: '',
      form: { active: true },
      id: null,
      rolesList: [],
      locales: [
        { value: 'en-US', label: 'English (USA)' },
        { value: 'fr-FR', label: 'Français (France)' },
      ],
      action: null,
      locked: false,
      locale: this.$store.state.auth.locale,
    };
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      email: { required, email },
      active: { required },
      role: { required },
      locale: { required },
      password: {
        minLength: minLength(8),
      },
      confirmPassword: {
        sameAs: sameAs('password'),
      },
    },
  },
  methods: {
    getTitle() {
      return this.id === 'new'
        ? this.$i18n.t('New user')
        : this.$i18n.t('{{lastname}} {{firstname}} details', { ...this.form });
    },
    toggleLock() {
      this.locked = !this.locked;
    },
    getError(error, panel) {
      this.panel = panel === 'preferences' && !this.panel ? panel : 'profile';
      const msg = [
        { name: 'required', message: 'This information is required.' },
        { name: 'requiredIf', message: 'This information is required.' },
        { name: 'minLength', message: 'This information is too short.' },
        { name: 'sameAs', message: 'These two data do not match.' },
        { name: 'email', message: 'Please enter a valid email.' },
      ].find((cond) => !error.cond) || { name: 'generic', message: 'Part of the information is not valid.' };
      return this.$i18n.t(msg.message);
    },

    goback() {
      this.$router.go(-1);
    },
    save() {
      this.$v.form.$touch();
      const params = { ...this.form };
      delete params.confirmPassword;
      if (!this.$v.form.$error) {
        this.panel = undefined;
        if (this.id !== 'new') {
          this.$axios.patch(`${process.env.AUTH_API}/users/${this.id}`, params).then((response) => {
            this.goback();
            this.$root.$emit('save', true);
          });
        } else {
          this.$axios.post(`${process.env.AUTH_API}/users`, params)
            .then((response) => {
              this.goback();
              this.$root.$emit('save', true);
            })
            .catch((error) => {
              if (error?.response.data.name === 'userexists') {
                this.$q.notify({ type: 'negative', message: 'User with that email is already existing', icon: 'error' });
              } else {
                this.$q.notify({ type: 'negative', message: error?.message, icon: 'error' });
              }
            });
        }
      } else {
        this.$refs.tabs.selectTab(this.panel);
      }
    },
    initData(route) {
      this.id = route.params.id;
      const reqs = [
        this.$axios.get(`${process.env.AUTH_API}/roles`),
      ];
      if (this.id !== 'new') {
        this.locked = true;
        reqs.push(this.$axios.get(`/users/${this.id}`));
      }
      Promise.all(reqs).then(([r, u]) => {
        this.rolesList = r.data.data.map((role) => ({
          label: this.localized(role.label),
          value: role.id,
        }));
        if (this.id !== 'new') {
          delete u.data.data.id;
          this.form = { ...u.data.data };
        }
        this.ready = true;
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to);
    next();
  },
  created() {
    this.initData(this.$route);
  },
};
</script>
<style lang="stylus" scoped>
.locked {
  cursor: pointer;
}
.drawer-panel {
  height: calc(100vh - 220px)
  overflow-y auto
}
.bottom {
  position: absolute
  bottom: 0
  display flex
  justify-content space-between
  width 100%
}
</style>
