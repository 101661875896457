<template>
  <div id="q-app">
    <q-ajax-bar />
    <router-view />
  </div>
</template>

<script>
import { sdk } from '@cabinetmariel/auth-api-sdk';

const variationcss = Symbol('variationcss'); // eslint-disable-line

export default {
  name: 'App',
  components: {},
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
  },
  data() {
    return {};
  },
  methods: {
    async init() {
      if (process.env.AUTH) {
        const { data: user } = await sdk.getMe({
          baseUrl: `${process.env.BASE_API}/auth`,
          credentials: 'include',
        });

        if (!user.id) {
          window.location.href = `${process.env.AUTH}?t=${process.env.INSTANCE}&returnUrl=${encodeURIComponent(window.location.href)}`;
          return;
        }

        this.$store.commit('auth/loginRaw', user);
      }

      Promise.all([
        this.$axios.get('/attributes', { params: { nolimit: 'yes' } }),
        this.$axios.get('/attributesets', { params: { full: 'yes' } }),
        this.$axios.get('/settings'),
        this.$axios.get('/decisiongrids/segmentation'),
      ])
        .then(([definitions, sets, settings, segmentationgrid]) => {
          const rawsets = sets.data.data;
          const rawdefinitions = definitions.data.data;

          rawdefinitions.forEach((d) => {
            if (d.settings && d.settings.lambda) d.settings.lambda = eval(d.settings.lambda); // eslint-disable-line
          });
          // new rate format
          if (settings.data.data.rates) {
            settings.data.data.rates = settings.data.data.rates.map((r) => {
              const tmp = (typeof r === 'string') ? { name: r, autoapply: true } : r;
              if (!tmp.label) tmp.label = { 'en-US': tmp.name };
              return tmp;
            });
          }
          this.$store.commit('general/setSettings', Object.freeze(settings.data.data));
          this.$store.commit('pricing/setAttributes', {
            rawsets,
            rawdefinitions,
            alldimensions: settings.data.data.alldimensions,
          });
          if (settings.data.data.defaultpriceset) {
            this.$store.commit('pricing/initPriceSet', settings.data.data.defaultpriceset);
          }
          this.$store.commit('general/setGrid', { name: 'segmentation', grid: segmentationgrid.data.data.grid });
        })
        .catch((error) => {
          console.error('LOGIPARTS FATAL', error);
          document.location = '/error';
        });
    },
  },
  watch: {
    $route(to, from) {
      if (from.name === 'login') this.init();
    },
    token() {
      if (this.token) {
        const ev = new EventSource(`${process.env.API}/messages${process.env.AUTH ? '' : `?access_token=${this.token}`}`, process.env.AUTH ? { withCredentials: true } : null);
        // ev.addEventListener('open', e => console.log('open', e));
        // ev.addEventListener('error', e => console.log('error', e));
        // ev.addEventListener('message', e => console.log('no event', e));
        ev.addEventListener('logiparts', (e) => {
          const data = JSON.parse(e.data);
          const message = JSON.parse(data.message);
          for (
            let i = 0;
            i < this.$store.state.auth.sse.observable.length;
            i += 1
          ) {
            if (
              data.channel.endsWith(this.$store.state.auth.sse.observable[i].regex)
            ) {
              this.$store.state.auth.sse.observable[i].func(message);
            }
          }
        });
      }
    },
  },
  mounted() {
    if (this.$route.name === 'error' || this.$route.name === 'login') return;
    this.init();
  },
};
</script>
<style>
.q-tree__node-header-content {
  padding-left: 10px;
}
</style>
