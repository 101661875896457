import { store } from './nrstore';

function mkpath(id, token) {
  // FIXME: we should use URL class
  return `${process.env.API}/store/${id}${process.env.AUTH ? '' : `?access_token=${token}`}`;
}
function mkproxypath(part, token) {
  // FIXME: we should use URL class
  return `${process.env.API}/store/${part.id}${process.env.AUTH ? '' : `?access_token=${token}&matnr=${part?.name?.v}&coc=${store.parts?.[part.nridx]?.coc?.v}`}`;
}
function mkproxypath2(part, token) {
  // FIXME: we should use URL class

  return `${process.env.API}/store/${part.id.v}${process.env.AUTH ? '' : `?access_token=${token}&matnr=${part?.name?.v}&coc=${part?.coc?.v}`}`;
}
export default {
  mkpath,
  mkproxypath,
  mkproxypath2,
};
