<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        Available exports
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.rule.definition.common" class="drawer-container">
      <p>{{$t('The following exports are available (click on the button to export):')}}</p>

      <div class="exportlist" v-for="ex in $store.state.general.settings.exports" :key="ex">
        <template v-if="xlsxexports[ex]">
          <div ><q-btn class="export-btn" dense :label="localized(xlsxexports[ex].label)" @click="xlsxexports[ex].generator" color="secondary" /></div>
          <div>{{localized(xlsxexports[ex].description)}}</div>
          <div></div>
          <div class="optionlist" v-if="xlsxexports[ex].options">
            <label v-for="option in xlsxexports[ex].options" :key="option.name">
              <input type="checkbox" v-model="exportoptions" :value="option.name">
              {{localized(option.label)}}
            </label>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      exportoptions: [],
      locale: this.$store.state.auth.locale,
    };
  },
  computed: {
    xlsxexports() {
      return {
        std: {
          label: { 'fr-FR': 'Générique', 'en-US': 'Generic' },
          description: { 'en-US': 'Exports the whole family parts (no pictures)' },
          generator: () => this.mkexport(),
        },
        m3: {
          label: { 'fr-FR': 'M3', 'en-US': 'M3' },
          description: { 'en-US': 'Generates an M3 compliant Excel file for the central' },
          generator: () => this.mkm3export(),
          options: [
            { name: 'alltags', label: { 'en-US': 'Also include the current pricelist', 'fr-FR': 'Inclure les prix dérivés' } },
            { name: 'vdiscount', label: { 'en-US': 'Include volume discounts', 'fr-FR': 'Inclure les prix paliers' } },
          ],
        },
        bobst1: {
          label: { 'fr-FR': 'Pricer', 'en-US': 'Pricer' },
          description: { 'en-US': 'Exports the pricer to an excel template.' },
          generator: () => this.bobst1(),
        },
        priceset: {
          label: { 'fr-FR': 'Price_S', 'en-US': 'Price_S' },
          description: { 'en-US': 'Exports all pricesets to an excel template with multiple sheets.' },
          generator: () => this.mkpricesetexport(),
        },
      };
    },
  },
  methods: {
    bobst1() {
      this.$store.dispatch('pricing/exportPricer', this.exportoptions);
    },
    mkexport() {
      this.$store.dispatch('pricing/exportParts', this.exportoptions);
    },
    mkpricesetexport() {
      this.$store.dispatch('pricing/exportPriceSets', this.exportoptions);
    },
    mkm3export() {
      this.$store.dispatch('pricing/exportM3', this.exportoptions);
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-container {
  padding: .5rem;
  div.exportlist {
    display grid
    grid-template-columns: 100px 1fr
    .export-btn {
      min-width 80px
    }
    .optionlist {
          display: flex;
    flex-direction: column;
    }
  }
}
</style>
