<template>
  <div class="login-standby" :class="{ 'login-standby-active': opened }">
    <div>
      <q-spinner-hourglass color="white" size="60px"/>
      <p>Please log-in using the popup window that appeared</p>
    </div>
  </div>
</template>
<script>
function PopupCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth // eslint-disable-line no-nested-ternary
    : (document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width);
  const height = window.innerHeight ? window.innerHeight // eslint-disable-line no-nested-ternary
    : (document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height);

  const systemZoom = width / window.screen.availWidth;
  const left = ((width - w) / 2 / systemZoom) + dualScreenLeft;
  const top = ((height - h) / 2 / systemZoom) + dualScreenTop;
  const newWindow = window.open(url, title, `location=no, scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (newWindow && newWindow.focus) newWindow.focus();
  return newWindow;
}
let loginwin = false;

export default {
  created() {
    window.addEventListener('message', ({ data }) => {
      if (typeof data === 'object' && data.logiparts && data.token && typeof loginwin?.close === 'function') {
        loginwin.close();
        loginwin = false;
        this.$store.dispatch('auth/login', data.token);
        if (this.$store.state.auth.user && this.$store.state.auth.user.locale) {
          this.$i18n.i18next.changeLanguage(this.$store.state.auth.user.locale);
        }
      }
    });
  },
  computed: {
    opened() {
      if (process.env.AUTH) {
        return false;
      }
      const status = !this.$store.state.auth.token;
      const url = this.$store.state.auth.subscheme ? this.$store.state.auth.subscheme.loginurl : 'bug';
      if (status && !loginwin) loginwin = PopupCenter(url, '', 500, 500);
      return status;
    },
  },
};
</script>
<style lang="stylus">
.login-standby {
  display: none;
  position: absolute;
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color #00000090
  z-index 5000
  div {
    width: 200px;
    text-align: center;
    color: white;
    font-size: 1rem;
  }
  &.login-standby-active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.login-box {
  max-width 400px;
  .align-right {
    text-align right
  }
}
</style>
