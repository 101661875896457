<template>
  <div>
    <q-page-container v-if="users">
      <q-page>
        <q-table
          class="fulltable"
          :data="users"
          :columns="columns"
          :pagination.sync="serverPagination"
          :rows-per-page-options="[]"
          :loading="loading"
          @request="fetch"
        >
          <template v-slot:header-cell="props">
            <q-th class="q-th-header" :props="props" @click="setSort(props.col)">
              {{ props.col.label }}
            </q-th>
          </template>
          <q-tr
            slot="body"
            slot-scope="props"
            :props="props"
            @click.native="rowClick(props.row)"
            class="cursor-pointer"
          >
            <q-td v-for="col in props.cols" :key="col.name" :props="props">{{ col.value }}</q-td>
          </q-tr>
        </q-table>
        <div class="action">
          <q-btn
            round
            color="secondary"
            icon="create_new_folder"
            class="add-user"
            @click="newUser()"
          />
        </div>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <router-view />
    </q-drawer>
  </div>
</template>
<script>
import { localization } from '../../../lib/mixins';

function togglesort(sort) {
  const sorts = {
    '.': '+',
    '+': '-',
    '-': '+',
  };
  return sorts[sort || '.'];
}

export default {
  mixins: [localization],
  components: {},
  data() {
    return {
      loading: false,
      serverPagination: {
        page: 1,
        rowsPerPage: 20,
        rowsNumber: null,
      },
      users: [],
      columns: [],
      requestSettings: {},
    };
  },
  computed: {
    drawer() {
      const paths = this.$route.path.split('/');
      return paths.includes('details');
    },
  },
  methods: {
    rowClick(row) {
      this.$router.push(`/settings/users/details/${row.id}`);
    },
    newUser() {
      this.$router.push('/settings/users/details/new');
    },
    setSort({ name }) {
      this.columns.forEach((column) => {
        column.sort = column.name === name ? togglesort(column.sort) : '.';
      });
      this.resetFetch();
    },
    hasSorts() {
      return this.columns.some((col) => col.sort !== '.');
    },
    parseSorts() {
      const result = this.columns.reduce((sortStr, col) => {
        if (col.sort === '+' || col.sort === '-') {
          sortStr += `${col.sort}${col.name},`;
        }

        return sortStr;
      }, '');
      return result.slice(0, -1);
    },
    resetFetch() {
      this.fetch({
        pagination: {
          rowsPerPage: this.serverPagination.rowsPerPage,
          page: 1,
        },
      });
    },
    fetch({ pagination }) {
      this.loading = true;
      const limit = pagination.rowsPerPage || 0;
      const offset = limit * (pagination.page - 1) || 0;
      Promise.all([
        this.$axios.get(`${process.env.AUTH_API}/users`, {
          params: {
            '@limit': limit,
            '@offset': offset,
            ...(this.hasSorts() && { sort: this.parseSorts() }),
          },
        }),
        this.$axios.get(`${process.env.AUTH_API}/roles`),
      ]).then(([u, r]) => {
        const users = u.data.data;
        const roles = r.data.data;
        const { meta } = u.data;
        this.serverPagination = {
          ...pagination,
          rowsNumber: meta.count,
        };
        this.$store.commit('users/setRoles', roles);
        users.forEach((user) => {
          user.role = roles.find((role) => role.id === user.role);
        });
        this.users.splice(0, this.users.length, ...users);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.columns = [
      {
        label: this.$i18n.t('Firstname'),
        field: 'firstname',
        align: 'left',
        sortable: true,
        name: 'firstname',
        sort: '.',
      },
      {
        label: this.$i18n.t('Lastname'),
        field: 'lastname',
        align: 'left',
        sortable: true,
        name: 'lastname',
        sort: '.',
      },
      {
        label: this.$i18n.t('Email'),
        field: 'email',
        align: 'left',
        sortable: true,
        name: 'email',
        sort: '.',
      },
      {
        label: this.$i18n.t('Role'),
        field: (row) => (row.role && row.role.label ? this.localized(row.role.label) : ''),
        align: 'left',
        sortable: true,
        name: 'role',
        sort: '.',
      },
      {
        label: this.$i18n.t('Active'),
        field: 'active',
        align: 'left',
        sortable: true,
        name: 'active',
        sort: '.',
      },
    ];
    this.fetch({ pagination: this.serverPagination });
  },
  created() {
    this.$root.$on('save', (bool) => {
      this.fetch({ pagination: this.serverPagination });
    });
    this.$store.commit('header/setHeader', {
      title: 'Users',
      subtitle: 'Settings',
    });
  },
  beforeDestroy() {
    this.$root.$off('save');
  },
};
</script>
<style lang="stylus">

.action {
  .add-user {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}

.highlight {
  background: rgba(0, 0, 0, 0.1);
}

.fulltable {
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);

  .q-table-middle, .q-table__middle {
    min-height: calc(100vh - 100px);
    height: calc(100vh - 100px);

    thead tr:first-child th {
      position: sticky;
      position: --webkit-sticky;
      top: 0;
      z-index: 900;
      background-color: $secondary;
      color: white
      font-weight bold
      border-bottom: 1px solid $light;
    }
  }

  .q-table-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
</style>
