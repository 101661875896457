<template>
  <table>
    <tr>
      <td v-for="(droppableCell, draggIndex) in draggableRow" v-if="getColspan(draggIndex - 1) !== 2" :key="draggIndex" :colspan="getColspan(draggIndex)"> <!-- eslint-disable-line -->
        <div class="drop-content">
          <Droppable :col-index="draggIndex"></Droppable>
          <div class="extend" v-if="isExpandable(draggIndex)" @click="toggleColumn(draggIndex)">
            <q-icon v-if="!isExpanded(draggIndex)" name="keyboard_arrow_right"/>
            <q-icon v-else name="keyboard_arrow_down"/>
          </div>
        </div>
      </td>
    </tr>
    <tr v-for="(line, tableIndex) in file.table" :key="tableIndex">
      <td
        :class="{ topBottomLine: checkTopBottomLine(tableIndex) }"
        v-for="(elem, cellIndex) in line"
        :key="cellIndex"
      >
        <div class="content">
          <span>{{elem}}</span>
          <Chip
            v-if="checkValidationElem(tableIndex, cellIndex)"
            :data="getDataChip(elem, cellIndex)"
          ></Chip>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import formatter from 'currencyformatter.js';
import Droppable from '../components/Droppable';

import Chip from '../components/Chip';

export default {
  components: {
    Droppable,
    Chip,
  },
  data() {
    return {
      impExp: this.$store.state.importExport,
      currencies: this.$store.state.importExport.step3.currencies,
      locales: this.$store.state.importExport.step3.locales,
    };
  },
  computed: {
    draggableRow: {
      get() {
        return this.$store.state.importExport.step3.draggableRow;
      },
    },
    draggableRow2: {
      get() {
        return this.$store.state.importExport.step3.draggableRow.filter((r, index) => (this.getColspan(index - 1) !== 2));
      },
    },
    file: {
      get() {
        return this.$store.state.importExport.step1.file;
      },
      set(data) {
        this.$store.commit('importExport/setFile', data);
      },
    },
  },
  methods: {
    deepClone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    hasNumber(value) {
      return /\d/.test(value);
    },
    isFourColumn(attribute) {
      return (
        attribute && attribute.expandable && attribute.expandable.includes(4)
      );
    },
    getColspan(draggIndex) {
      const cellAttribute = this.getCellAttribute(draggIndex, 'dragg');
      const twoColAttribute = ['pricing', 'interval'];
      if (cellAttribute && twoColAttribute.includes(cellAttribute.type)) {
        return 2;
      }
      return 1;
    },
    getCellAttribute(index) {
      if (this.draggableRow[index] && this.draggableRow[index][0]) {
        return this.draggableRow[index][0];
      }
      return false;
    },
    isExpandable(draggIndex) {
      const cellAttribute = this.getCellAttribute(draggIndex, 'dragg');
      return cellAttribute && cellAttribute.expandable;
    },
    isExpanded(realDraggIndex) {
      return this.getCellAttribute(realDraggIndex).expanded;
    },
    toggleColumn(draggIndex) {
      if (!this.isExpanded(draggIndex)) {
        this.$store.dispatch('importExport/mergeColumn', draggIndex);
      } else {
        this.$store.dispatch('importExport/unmergeColumn', draggIndex);
      }
    },
    checkTopBottomLine(tableIndex) {
      const checkHeader = tableIndex
        < this.$store.state.importExport.step2.form.headingLines.value;
      const checkBottom = tableIndex
        >= this.file.table.length
          - this.$store.state.importExport.step2.form.footerLines.value;
      return checkHeader || checkBottom;
    },
    checkValidationElem(tableIndex, cellIndex) {
      const cellAttribute = this.getCellAttribute(cellIndex, 'table');
      if (cellAttribute) {
        return !this.checkTopBottomLine(tableIndex);
      }
      return false;
    },
    getDataChip(elem, cellIndex) {
      const cellAttribute = this.getCellAttribute(cellIndex, 'table');
      if (cellAttribute) {
        switch (cellAttribute.type) {
          case 'amount':
            return this.getAmountChip(elem);
          case 'pricing':
            return this.getPricingChip(elem);
          case 'price-unit':
            return this.getNumberChip(elem);
          case 'string':
          case 'collection':
          case 'localizedstring':
          case 'boolset':
            return this.getStringChip(elem);
          case 'boolean':
            return this.getBooleanChip(elem);
          case 'number':
            return this.getNumberChip(elem);
          case 'interval':
            return this.getDateChip(elem);
          case 'interval-to':
            return this.getDateChip(elem);
          case 'date':
            return this.getDateChip(elem);
          case 'rate':
            return this.getNumberChip(elem);
          case 'segmentation':
            return this.getStringChip(elem);
          default:
            return null;
        }
      }
      return null;
    },
    getCurrencies(value) {
      const parsedCurrencie = value.toString().replace(/[-+0-9,\s.\t]/gi, '');
      if (parsedCurrencie === '') {
        const defaultcurrency = this.currencies && this.currencies.value && this.currencies.value.value ? this.currencies.value.value : this.$store.state.general.settings.currency || 'EUR';
        return [
          this.currencies.data.find((val) => val.value === defaultcurrency) || defaultcurrency,
        ];
      }
      return this.currencies.data.filter((elem) => elem.symbol === parsedCurrencie || elem.value === parsedCurrencie);
    },
    getAmountChip(value) {
      if (!this.hasNumber(value)) {
        return this.returnObjChip('FAIL', 'INVALID');
      }
      const amount = formatter.parse(value, { locale: this.locales.value });
      const currencies = this.getCurrencies(value);
      if (currencies.length === 1) {
        return this.returnObjChip(
          'SUCCESS',
          `${amount} ${currencies[0].value}`,
        );
      } if (currencies.length > 1) {
        const curr = this.$store.state.importExport.step3.similarCurrencies.find((val) => val.symbol === currencies[0].symbol);
        return this.returnObjChip('WARNING', `${amount} ${curr.defaultUnit}`);
      }
      return this.returnObjChip('FAIL', 'INVALID');
    },
    getPricingChip(value) {
      if (value === undefined || (typeof value === 'string' && value.trim() === '')) {
        return this.returnObjChip('EMPTY', this.$i18n.t('Empty'));
      }
      if (!this.hasNumber(value)) {
        return this.returnObjChip('FAIL', 'INVALID');
      }
      const amount = typeof value === 'number' ? value : formatter.parse(value, { locale: this.locales.value });
      const currencies = this.getCurrencies(value);
      if (currencies.length === 1) {
        return this.returnObjChip(
          'SUCCESS',
          `${amount} ${currencies[0].value}`,
        );
      } if (currencies.length > 1) {
        const curr = this.$store.state.importExport.step3.similarCurrencies.find((val) => val.symbol === currencies[0].symbol);
        return this.returnObjChip('WARNING', `${amount} ${curr.defaultUnit}`);
      }
      return this.returnObjChip('FAIL', 'INVALID');
    },
    getStringChip(value) {
      if (value === undefined) return this.returnObjChip('EMPTY', this.$i18n.t('Empty'));
      if (value.length > 0) {
        return this.returnObjChip('SUCCESS', 'VALID');
      }
      return this.returnObjChip('FAIL', 'EMPTY');
    },
    getBooleanChip(value) {
      if (value === undefined) return this.returnObjChip('EMPTY', this.$i18n.t('Empty'));
      switch (typeof value === 'string' ? value.toLowerCase() : value) {
        case '1':
        case true:
        case 1:
        case false:
        case 0:
          return this.returnObjChip('SUCCESS', 'VALID');
        case '0':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'oui':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'non':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'true':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'false':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'vrai':
          return this.returnObjChip('SUCCESS', 'VALID');
        case 'faux':
          return this.returnObjChip('SUCCESS', 'VALID');
        default:
          return this.returnObjChip('FAIL', 'INVALID');
      }
    },
    getDateChip(value) {
      if (value === undefined) return this.returnObjChip('EMPTY', this.$i18n.t('Empty'));
      let date;
      if (typeof value === 'number') {
        date = new Date((value - 25569) * 86400 * 1000);
      } else {
        date = new Date(value.replace(/[\u2212./]/g, '-'));
        if (date.toString() === 'Invalid Date') {
          return this.returnObjChip('FAIL', 'INVALID');
        }
      }
      // const strDate = date.toLocaleDateString(this.locales.value.replace(/[_]/gi, '-'));
      const strDate = date.toISOString().split('T')[0];
      return this.returnObjChip('SUCCESS', strDate);
    },
    getUnit(value) {
      return typeof value === 'number' ? '' : value.replace(/[0-9,\s.\t]/gi, '');
    },
    getNumberChip(value) {
      if (value === undefined || (typeof value === 'string' && value.trim() === '')) {
        return this.returnObjChip('EMPTY', this.$i18n.t('Empty'));
      }
      if (!this.hasNumber(value)) {
        return this.returnObjChip('FAIL', 'INVALID');
      }
      const number = typeof value === 'number' ? value : formatter.parse(value, { locale: this.locales.value });
      const unit = this.getUnit(value);
      return this.returnObjChip('SUCCESS', `${number} ${unit}`);
    },
    returnObjChip(status, value) {
      switch (status) {
        case 'EMPTY':
          return {
            color: 'secondary',
            icon: 'check',
            value,
          };
        case 'SUCCESS':
          return {
            color: 'green',
            icon: 'check',
            value,
          };
        case 'WARNING': {
          return {
            color: 'orange',
            icon: 'check',
            value,
          };
        }
        case 'FAIL': {
          return {
            color: 'red',
            icon: 'close',
            value,
          };
        }
        default:
          return {
            color: 'red',
            icon: 'close',
            value,
          };
      }
    },
  },
  created() {},
};
</script>

<style lang="stylus" scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;

  tr {
    td {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      padding: 5px 30px;

      .drop-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .extend {
        font-size: 2rem;
        cursor: pointer;

        &:hover {
          font-size: 3rem;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .q-chip-right {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.topBottomLine {
        // opacity: 0.7;
        font-weight: bold;
      }
    }
  }
}
</style>
