<template>
  <div class="lg-attribute" :disabled="disabled">
    <div class="lg-attribute-content">
      <div class="lg-attribute-label" v-if="label" :title="label">{{label}}</div>
      <div class="lg-attribute-value" >
        <div v-if="def.timeserie" class="lg-sparlines">
          <div class="lg-sparkline" v-for="(taggedserie, index) in taggedseries" :key="taggedserie[0]"  v-show="displayed === index">
            <div @click="disabled ? null: inc()" class="lg-sparkline-set" v-if="taggedseries.length > 1">{{taggedserie[0]}}</div>
            <div class="spark" v-if="(taggedserie[1].length > 1) && (space === 'standard' || space === 'expandable' || space === 'expanded')  && ['number','amount','pricing','rate'].includes(def.type)">
              <sparkline>
                <sparklineBar :data="taggedserie[1].slice().reverse().map(i => i.v.number)" :limit="taggedserie[1].length" :styles="barstyle" />
              </sparkline>
            </div>
            <component v-if="taggedserie[1][sidx] >= 0" :is="normalize(def.type)" :data="taggedserie[1][taggedserie[1][sidx]]" :def="def" :locale="locale" :wrap="wrap"
              :align="align" :contentstyle="contentstyle" @change="dochange" />
            <div v-else></div>
            <q-badge class="status-badge" v-if="(taggedserie[1][idx] || {}).syncstatus && def.sync && !(edition || mode === 'edition')"
              :title="status(taggedserie[1][idx].syncstatus).label"
              :color="status(taggedserie[1][idx].syncstatus).color" text-color="white"
              :label="status(taggedserie[1][idx].syncstatus).badge" />
          </div>
        </div>
        <div v-else>
          <component :space="space" :is="normalize(def.type)" :data="data" :def="def" :locale="locale" :href="href"
            :editable="edition || mode === 'edition'" :wrap="wrap" :align="align"  :contentstyle="contentstyle"
            @change="dochange" />
          <q-badge class="status-badge" v-if="(data || {}).syncstatus && def.sync && !(edition || mode === 'edition')"
             :title="status(data.syncstatus).label"
             :color="status(data.syncstatus).color" text-color="white"
             :label="status(data.syncstatus).badge" />
        </div>

      </div>
      <div class="lg-attribute-buttons" v-if="!(mode === 'view-only')">
        <q-btn class="lg-attribute-action" :color="$store.state.product.attributes[def.name]?'primary':'black'" v-if="edition" flat round size="sm" icon="check" @click="disabled ? null: propagate()" />
        <q-btn class="lg-attribute-action" v-if="!(edition || def.readonly || def.computed || def.timeserie || mode === 'view-only')" flat round size="sm" icon="edit" @click="disabled ? null: enteredition()"/>
        <q-btn class="lg-attribute-action" v-if="def.timeserie && space === 'expandable'" flat round  size="sm" icon="visibility"  @click="expanded=!expanded"/>
        <q-btn class="lg-attribute-action" v-if="edition" flat round size="sm" icon="cancel" @click="disabled ? null: exitedition()"/>
      </div>
    </div>

    <div class="lg-attribute-timeserie-history" v-if="def.timeserie && (expanded || space === 'expanded')">
      <q-tabs
        v-model="tab"
        dense
        align="left"
        :breakpoint="0">
        <q-tab v-for="(tag, index) in tabs" :name="tag" :label="tag" :key="index" />
        <q-tab name="__add" icon="add" v-if="def.settings && def.settings.tags && def.settings.tags.length"/>
      </q-tabs>
      <q-separator />
      <q-tab-panels  v-model="tab">
        <q-tab-panel v-for="(tag, index) in tabs" :name="tag" :key="index">
          <table class="q-table">
            <thead>
              <tr><th colspan="2">Intervalle</th><th>Valeur</th>
              <th v-if="(!(def.computed || def.readonly || mode === 'view-only' || disabled)) || canUpdateTimeSerie(def.name)">&nbsp;</th></tr>
            </thead>
            <tbody>
              <tr v-if="!cannotmodify">
                <td>
                  <DateField :value="ts.from" :disabled="false" name="from" @change="setdate" :locale="userLocale"></DateField>
                </td>
                <td>
                  <DateField :value="ts.to" :disabled="cannotEditToDate(tab)" name="to" @change="setdate" :locale="userLocale"></DateField>
                </td>
                <td class="newvalue">
                  <q-input class="smallinput" dense filled square v-model="ts.value" :type="isnumber(def.type)?'number':'text'" >
                  </q-input>
                  <q-select class="smallselect" v-if="['number', 'amount', 'pricing'].includes(def.type)" dense filled square v-model="ts.unit" :options="units" />
                  <span class="qtysep">/</span>
                  <q-input class="smallinput" v-if="['pricing'].includes(def.type)"  dense filled square v-model="ts.qtyvalue" type="number" />
                  <q-select class="smallselect" v-if="['pricing'].includes(def.type)" dense filled square v-model="ts.qtyunit" :options="settings.units.number.filter((u) => !!u)" />
                </td>
                <td><q-btn dense icon="check" color="primary" @click="disabled ? null: pushserie()" /></td>
              </tr>

              <template v-for="(row, index2) in data[tag]" ><tr :key="index2+'key1'">
                <td v-for="(boundary, index3) in formatRowDates(row.t, true)" :key="index3+'key2'">
                  {{boundary.date}}
                  <q-btn @click="closeTag(row)" v-if="index2===0 && index3===1 && canUpdateTimeSerie(def.name) && !isPast(row.t) && !cannotmodify && boundary.date !== '-'" icon="close" dense size="sm" style="display: inline-block; float:right;"/>
                </td>
                <td :title="`Changed on ${formatdate(row.updated_at)} by ${row.email?row.email:'unknown@user'}`">
                  <component :is="def.type" :data="row" :def="def" :locale="locale" />
                </td>
                <td v-if="canUpdateTimeSerie(def.name)">
                  <q-btn v-if="!cannotmodify && row.t !== 'empty'" @click="rmTag(row, tag, index2)" icon="delete" dense size="sm"/>
                  <q-btn v-if="row.t === 'empty'" icon="error" color="red" dense size="sm" :title="$t('Invalid data got there. Contact support for more info')"/>
                </td>
              </tr>
              <tr style="padding-left: .5rem;" :key="index2" v-if="related && index2===0 && settings.vdiscount">
                <td style="border-left: 1px solid gray;" colspan="3">{{$t('Volume discounts disabled')}}</td>
              </tr></template>

            </tbody>
          </table>
        </q-tab-panel>
        <q-tab-panel name="__add">
          <q-select v-model="newtab" filled square dense label="Empty set to display"
            :options="remainingtags"/>
          <p>Please note that :</p>
          <ul><li>This will display the empty set temporarily for you to add a new value.</li></ul>
          <q-btn label="Add" @click="disabled ? null: addtab"/>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script>
import sparkline from 'vue-sparklines';
import { can } from '@cabinetmariel/scopes';
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import { localization } from '../../lib/mixins';
import DateField from './DateField';
import { dateDefs } from './datedefs';

const { utils } = pricingUtilities;
const { findactualdate } = utils;
const supportedtypes = [
  'string',
  'localizedstring',
  'segmentation',
  'pricing',
  'pricingarray',
  'number',
  'boolean',
  'amount',
  'interval',
  'unsupported',
  'collection',
  'documentset',
  'date',
];

const supportedcomponents = supportedtypes.reduce((_, t) => {
  _[t] = () => import('./' + t); // eslint-disable-line
  return _;
}, {});
const statuses = {
  waiting: { label: 'Waiting to be sent to ERP', badge: 'W', color: 'orange' },
  transmitted: { label: 'Transmitted to ERP', badge: 'T', color: 'light-green' },
  active: { label: 'Active, fully implemented', badge: 'A', color: 'positive' },
  rejected: { label: 'Rejected by ERP import tool', badge: 'R', color: 'negative' },
  inconsistent: { label: 'Inconsistent between pricing and ERP', badge: 'I', color: 'negative' },
  acknowledged: { label: 'Accepted for processing by ERP', badge: 'K', color: 'light-green' },
};
export default {
  mixins: [localization],
  props: {
    dataset: String,
    align: String,
    data: Object,
    related: Object,
    idx: { type: Number, default: 0 },
    def: Object,
    locale: String,
    href: String,
    owner: { type: Boolean, default: false },
    mode: {
      type: String,
      default: 'view-only',
    },
    space: {
      type: String,
      default: 'compact',
    },
    wrap: {
      type: String,
      default: 'yes',
    },
    when: Date,
    contentstyle: String,
    inline: String,
    label: String,
    settings: Object,
    disabled: Boolean,
  },
  components: { ...supportedcomponents, sparkline, DateField },
  data() {
    return {
      sidx: Symbol('idx'),
      newtab: null,
      ts: {
        from: (new Date()).toISOString().split('T')[0],
        to: can('admin', this.$store.state.auth.user.scopes) ? '' : '9999-12-31',
        value: '',
        qtyvalue: 1,
        qtyunit: '',
      },
      tab: this.def.timeserie && this.data ? Object.keys(this.data).filter((k) => (k !== 'set'))[0] : '',
      expanded: false,
      displayed: 0,
      edition: false,
      barstyle: {
        fill: '#ccc',
      },
      chartoptions: {
        chart: {
          height: 48,
          width: 300,
          type: 'line',
          stacked: false,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yyyy',
          },
          y: {
            formatter(v) {
              return v.toFixed(2);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#FF1654'],
        xaxis: {
          type: 'datetime',
        },
        stroke: {
          width: 7,
          // curve: 'smooth',
        },
        markers: {
          size: 4,
          colors: ['#FFA41B'],
          strokeColors: '#fff',
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        yaxis:
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#247BA0',
            },
            labels: {
              style: {
                colors: '#247BA0',
              },
            },
            title: {
              text: 'Series B',
              style: {
                color: '#247BA0',
              },
            },
          },
      },
    };
  },
  computed: {
    cannotmodify() {
      return (this.def.computed || this.def.readonly || this.mode === 'view-only' || this.disabled);
    },
    userLocale() {
      switch (this.$store.state.auth.user.locale) {
        case 'en_US':
        case 'en-US':
        case 'en_GB':
        case 'en-GB':
        case 'en':
          return 'en-US';
        case 'fr_CH':
        case 'fr-CH':
          return 'fr-CH';
        case 'fr_FR':
        case 'fr-FR':
        case 'fr':
          return 'fr-FR';
        default:
          return 'en-US';
      }
    },
    tabs() {
      const keys = Object.keys(this.data).filter((k) => k !== 'set').sort();
      if (this.def.settings && this.def.settings.tags && this.def.settings.tags.length && !keys.includes(this.def.settings.tags[0])) {
        keys.push(this.def.settings.tags[0]);
      }
      return keys;
    },
    units() {
      if (!(this.settings && this.settings.units)) return ['!!!'];
      if (this.def.type === 'number' && this.settings.units.number) return this.settings.units.number;
      if ((this.def.type === 'amount' || this.def.type === 'pricing') && this.settings.units.amount) return this.settings.units.amount;
      return ['!!!'];
    },
    remainingtags() {
      if (!this.def.timeserie) return [];
      if (!(this.def.settings && this.def.settings.tags && this.def.settings.tags.length)) return [];
      const used = Object.keys(this.data);
      return this.def.settings.tags.filter((t) => !used.includes(t));
    },
    taggedseries() {
      if (!this.data) return [];
      const series = this.dataset
        ? Object.entries(this.data).filter(([tag, serie]) => ((tag === this.dataset) && (serie.length > 0)))
        : Object.entries(this.data).filter(([tag, serie]) => ((tag !== 'set') && (serie.length > 0)));
      series.forEach(([, serie]) => {
        if (!this.when) {
          serie[this.sidx] = this.idx;
        } else {
          serie[this.sidx] = findactualdate(serie, this.when);
        }
      });
      return series;
    },
  },
  methods: {
    cannotEditToDate(tab) {
      return !(can('admin', this.$store.state.auth.user.scopes) || can(`timeseries.${this.def.name}:${tab}`, this.$store.state.auth.user.scopes));
    },
    canUpdateTimeSerie(attributeName) {
      if (attributeName === 'price') return Boolean(this.settings.canEditPriceTimeSerie);
      return true;
    },
    infinity(value) {
      return this.settings.infinity || value;
    },
    normalize(type) {
      if (type.endsWith('[]')) return `${type.slice(0, -2)}array`;
      return type;
    },
    isPast(t) {
      const from = this.parseinterval(t)[0].date.split('-');
      const f = new Date(from[0], from[1] - 1, from[2]);
      return f > new Date();
    },
    closeTag(row) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.actualvalue = {
        t: `[${this.parseinterval(row.t)[0].date}, ${tomorrow.toISOString().split('T')[0]})`,
        close: row.id,
        [this.def.name]: row.v,
      };
      this.propagate();
    },
    rmTag(row, tag, idx) {
      this.actualvalue = {
        // t: row.t,
        delete: row.id,
        [this.def.name]: null,
      };
      this.propagate();
    },
    formatdate(d) {
      if (!d) return '';
      return (new Date(d)).toLocaleDateString();
    },
    addtab() {
      this.$set(this.data, this.newtab, []);
      this.newtab = null;
    },
    isnumber(type) {
      return ['number', 'amount', 'pricing'].includes(type);
    },
    pushserie() {
      let v;
      let qty;
      let unit;
      switch (this.def.type) {
        case 'pricing':
          qty = { qty: ((this.data[this.tab] || [])[0] || { v: {} }).v.qty };
          if (this.ts.qtyunit) {
            qty = { qty: { unit: this.ts.qtyunit, number: parseFloat(this.ts.qtyvalue), fixed: this.ts.qtyvalue.toString() } };
          }
        // eslint-disable-next-line no-fallthrough
        case 'number':
        case 'amount':
          unit = this.ts.unit;
          v = {
            fixed: this.ts.value,
            number: parseFloat(this.ts.value),
            unit,
            ...qty,
          };
          break;
        case 'string':
        case 'collection':
        default:
          v = this.ts.v.toString().trim();
      }
      const tomorrow = new Date(this.ts.to || 0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const { from } = this.ts;
      const to = (!this.ts.to || this.ts.to === '9999-12-31') ? '' : tomorrow.toISOString().split('T')[0];
      this.actualvalue = {
        t: `[${from},${to})`,
        tag: this.tab,
        [this.def.name]: v,
      };
      if (from !== to) return this.propagate();
      return this.$emit('error', this.$t('Invalid interval'));
    },
    status(s) {
      return statuses[s] ? { label: this.$t(statuses[s].label), badge: statuses[s].badge, color: statuses[s].color } : { label: this.$t('Unknown status'), badge: 'U' };
    },
    chartserie(taggedserie) {
      const data = (taggedserie && taggedserie[1] && taggedserie[1].length)
        ? taggedserie[1].map((i) => ({ x: new Date(i.t.split(',')[0].slice(1)).getTime(), y: i.v.number }))
        : [];
      return [{ data }];
    },
    dochange(val) {
      this.actualvalue = val;
    },
    setdate(val) {
      this.ts = { ...this.ts, ...val };
    },
    justify(type) {
      switch (type) {
        case 'pricing':
        case 'number':
        case 'amount':
          return 'lg-attribute-right';
        case 'interval':
        case 'unsupported':
        case 'boolean':
        case 'collection':
        case 'string':
        case 'localizedstring':
          return 'lg-attribute-center';

        case 'segmentation':
        default:
          return 'lg-attribute-left';
      }
    },
    propagate() {
      if (this.actualvalue) {
        if (['string', 'localizedstring', 'collection'].includes(this.def.type) && this.actualvalue[this.def.name]) {
          this.actualvalue[this.def.name] = this.$utils.labeltrim(this.actualvalue[this.def.name]);
        }
        this.$emit('update', this.actualvalue);
      }
      this.exitedition();
    },
    nbbuttons() {
      if (this.edition) return 2;
      if (this.mode === 'view-only' && (!this.def.timeserie || this.space === 'compact' || this.space === 'standard')) return 0;
      return 1;
    },
    exitedition() {
      this.edition = false;
    },
    enteredition() {
      this.edition = true;
    },
    inc() {
      this.displayed = (this.displayed + 1) % this.taggedseries.length;
    },
    formatDate(date, locale) {
      const formatter = (dateDefs[locale] || dateDefs['en-US']).getLocaleDate;
      return formatter(date);
    },
    parseinterval(t) {
      if (t === undefined) {
        return [{}, {}];
      }
      if (t === 'empty') {
        return [{ type: 0, date: '-' }, { type: 1, date: '-' }];
      }
      return t.replace(/[[()\]]/g, '').split(',').map((b, i) => ({ type: i, date: b || this.infinity('∞') }));
    },

    formatRowDates(t, useIncludedTo) {
      const result = this.parseinterval(t);
      if (useIncludedTo && !['-', '∞'].includes(result[1].date)) {
        const inf = result[1].date === this.infinity('∞');
        const d = new Date(result[1].date);
        if (!inf) {
          d.setDate(d.getDate() - 1);
        }
        result[1] = {
          type: 1,
          date: d.toISOString().split('T')[0],
        };
      }

      return result.map((value) => ({
        ...value,
        date: ['-', '∞'].includes(value.date) ? value.date : this.formatDate(new Date(value.date), this.userLocale || 'en-US'),
      }));
    },
  },
};
</script>
<style lang="stylus" scoped>
.smallinput {
  width: 80px
}
.smallselect {
  min-width: 80px
}
.qtysep {
  font-size: 2rem
}
.lg-attribute {
  height 100%
  .lg-attribute-content {
    display flex
    align-items center
    .lg-attribute-right {
      justify-content flex-end
    }
    .lg-attribute-center {
      justify-content center
    }
    .lg-attribute-left {
      justify-content flex-start
    }
    .lg-attribute-label {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .lg-attribute-value {
      height 100%
      flex: 1;
      display flex
      max-width: 100%;
      align-items center
      .lg-sparlines {
        height 100%
        flex 1
        .lg-sparkline {
          height 100%
          display flex
          align-items center
          position relative
          .status-badge {
            position: absolute
            top: 4px;
          }
          .lg-sparkline-set {
            writing-mode: vertical-rl;
            transform: rotate(-180deg);
            overflow hidden
            background $grey-2
            height 36px
          }
        }
      }
    }
  }
}
.history-owner {
  color: gray;
  font-size: .6rem;
  font-style: italic;
  text-align: right;
}
.lg-attribute-timeserie-history {
  height: calc(100% - 42px);
  .q-tab-panel {
    padding-left 0
    padding-right 0
  }
  .q-tab-panels {
    height: calc(100% - 38px);;
  }
  .q-table {
    tr {
      height auto
      min-height auto

      td {
        padding .2rem
        height auto
        .q-input, .q-select {
          padding: 0;
        }
        .q-select {
          margin-left: .2rem
        }
        &.newvalue {
          display flex
          >>> .q-field__control {
            padding: 0
          }
        }
      }
      td:last-child, th:last-child {
        width: 50px;
      }
    }
    tbody {
      .pricing {
        text-align right
      }
      tr:first-child {
        td {
          font-weight bold
        }
      }
    }
  }
}
</style>
