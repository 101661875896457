import axios from 'axios';
import Vue from 'vue';

const EventBus = new Vue();

export const standby = (context, payload) => {
  const { config, data } = payload;
  context.commit('invalidate', data);
  return new Promise((resolve) => {
    EventBus.$on('successful-login', () => resolve(axios(config)));
  });
};

export const login = (context, payload) => {
  context.commit('login', payload);
  EventBus.$emit('successful-login');
};

export const loginRaw = (context, payload) => {
  context.commit('loginRaw', payload);
  EventBus.$emit('successful-login');
};
